import React from "react";

export default function Section({ id }) {
  return (
    
      <div className="section-content" id={id}>
      </div>
    
  );
}

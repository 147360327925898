import React from "react";

import Footer from "./components/Footer";
import Home from "./pages/Home";





function App() {
  
  
  return (
    <>
    <Home />
    <Footer />
   </>
  );
}

export default App;
import React from "react";
import "./style.css";


function WrapperD(props) {

    return (
    <div className="wrapperD">{props.children}</div>
    )
}



export default WrapperD;